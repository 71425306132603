<template>
  <TTView>
    <VRow>
      <VCol>
        <VBreadcrumbs
          class="pl-0"
          :items="breadcrumbs"
        />
      </VCol>
    </VRow>
    <VRow align="center">
      <VCol
        cols="auto"
        class="form-title"
      >
        <div class="d-flex align-center">
          <CompanyAvatar :url="image" />
          {{ company.name }}
        </div>
      </VCol>
    </VRow>
    <template v-if="groups.length > 0">
      <VRow>
        <VCol align="right">
          <VBtn
            color="primary"
            :to="{ name : Names.R_LEARNING_ASSESSMENTS_CREATE }"
          >
            <VIcon left>
              fal fa-plus
            </VIcon>

            Создать оценку
          </VBtn>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <TTDataTable
            :loading="loading"
            :headers="headers"
            :items="groups"
            @click:row="handleGroupClick"
          >
            <template #item.procedures="{ item : group }">
              <template v-if="group.procedures.length === 0">
                Не добавлены
              </template>

              <VChipGroup
                v-else
                column
              >
                <VChip
                  v-for="procedure in group.procedures"
                  :key="procedure.id"
                  small
                >
                  {{ procedure.name }}
                </VChip>
              </VChipGroup>
            </template>
          </TTDataTable>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <VPagination
            v-model="pagination.page"
            circle
            :disabled="loading"
            :length="totalPages"
            :total-visible="pagination.limit"
          />
        </VCol>
      </VRow>
    </template>
    <template v-else-if="!loading">
      <VRow>
        <VCol
          align="center"
          justify="center"
        >
          <VImg
            width="102"
            :src="require('@/assets/male-mexican-hat.png')"
          />
          <div class="empty-company">
            Нет групп оценок в компании
          </div>

          <RouterLink :to="{ name : Names.R_LEARNING_ASSESSMENTS_CREATE }">
            Создать оценку
          </RouterLink>
        </VCol>
      </VRow>
    </template>
  </TTView>
</template>

<script>

import { getGoghaImageUrl } from '@front.backoffice/common';
import CompanyAvatar from '../../components/assessments/CompanyAvatar.vue';

export default {
  name: 'GroupsList',
  components: { CompanyAvatar },
  inject: ['Names'],

  props: {
    accountId: {
      type: String,
      required: true,
    },

    companyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: 'Название группы оценки', value: 'name', sortable: false },
        { text: 'Команда', value: 'teamId', sortable: false },
        { text: 'Пользователь', value: 'userId', sortable: false },
        {
          text: 'Процедуры',
          value: 'procedures',
          sortable: false,
          width: '200px',
        },
      ],
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
      },
      groups: [],
      company: {},
      account: {},
    };
  },

  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.account.name },
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.company.name },
      ];

      return breadcrumbs;
    },
    image() {
      if (this.company.id) return getGoghaImageUrl({ uuid: this.company.id, ...this.company.image });
      return null;
    },
    totalPages() {
      const totalPages = Math.ceil(this.pagination.total / this.pagination.limit);

      return totalPages > 0 ? totalPages : 1;
    },
  },

  watch: {
    'pagination.page': async function handler() {
      await this.fetch();
    },
  },

  async created() {
    await this.fetch();
    this.getCompanyAndAccount();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const dto = { companyId: this.companyId, ...this.pagination };
        const app = new this.$di.ddd.Assessment();
        const [{ groups, pagination }] = await app.services.assessment.fetchCompanyGroups(dto);
        this.groups = groups;
        this.pagination = {
          page: Number(pagination.page),
          limit: Number(pagination.limit),
          total: Number(pagination.total),
        };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async getCompanyAndAccount() {
      try {
        const { companyId, accountId } = this;

        const accountsApp = new this.$di.ddd.Accounts();
        const [account] = await accountsApp.services.account.getAccount({ id: accountId });
        const [company] = await accountsApp.services.account.getCompany({ id: companyId });

        this.account = account;
        this.company = company;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },

    handleGroupClick(item) {
      this.$router.push({
        name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW,
        params: {
          groupId: item.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.empty-company {
  margin: 12px 0 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #0b1218;
}
.form-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.6px;
  color: #0b1218;
}
</style>
